@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
/*@import '../../vendor/spatie/laravel-medialibrary-pro/resources/js/media-library-pro-styles';*/
@import '/media-library-pro/styles.css';


@import "quickmenu/dist/css/quickmenu.min.css";

html{
    /*scroll-behavior: smooth;*/
}
.quick-menu.navigation .button {
    @apply lg:hidden
}

.quick-menu.navigation .menu {
    @apply w-full
}

.quick-menu.navigation .menu .menu-close {
   @apply bg-white
}


.quick-menu.navigation .menu nav ul li a{
    @apply  border-none text-white
}

@layer utilities {
    .snap-direction-x {
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        -ms-overflow-style: none;
        scroll-behavior: smooth;
    }

    .snap-align-start {
        scroll-snap-align: start;
    }

    .scroll-stop {
        scroll-margin: 200px;
    }

    .snap::-webkit-scrollbar {
        display: none;
    }

    .widescreen {
        aspect-ratio: 2/1;
    }

}

